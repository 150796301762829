import { useTranslation } from "react-i18next"
import { formatDate } from "helpers/FormatDateHelper"
import { getStatusTranslateValue } from "overrides/theme/entel/base/helpers/status_helper"
import { useParams } from "react-router-dom"

const OrderDetailTraces = (props: any) => {
  const { t } = useTranslation()
  const { shippingtype }: any = useParams()
  const { data } = props

  const template = data?._sg?.trace && (
    <article className="order__traces">
      <h2>{t("Traces")}</h2>
      <section>
        {data?._sg.trace?.map((item: any, index: number) => (
          <article className="order__traces__container" key={index}>
            <h4 className="order__traces__title">
              <span>Traza de estados</span>
              <span>{formatDate(item?.date)}</span>
            </h4>
            <div className="order__traces__body">
              <h5>{t("Descripción")}</h5>
              <p className="order__traces__body__text order__traces__body__text--description">{item?.description}</p>
              <h5>{t("Estado Trace")}</h5>
              <p className="order__traces__body__text order__traces__body__text--description">
                {getStatusTranslateValue(item?.state, shippingtype.toUpperCase())?.title}
              </p>

              <h5>{t("Información adicional")}</h5>
              {item?.custom?.hasOwnProperty("attempt") ? (
                <p className="order__traces__body__text order__traces__body__text--custom-key">
                  <span>Intento:</span> <span>{Number(item?.custom?.attempt)}</span>
                </p>
              ) : null}
              {item?.custom?.executer ? (
                <p className="order__traces__body__text order__traces__body__text--custom-key">
                  <span>Realizada por:</span>{" "}
                  <span>{`${item?.custom?.executer?.name} (${item?.custom?.executer?.id})`}</span>
                </p>
              ) : null}
            </div>
          </article>
        ))}
      </section>
    </article>
  )

  return template || <></>
}

export default OrderDetailTraces
