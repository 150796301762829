import { Fragment, useContext } from "react"
import { GlobalContext } from "context/context"
import { createPortal } from "react-dom"
import logo_entel from "assets/images/logo_entel.svg"
import { useAuth0 } from "@auth0/auth0-react"
import useAlertSessionError from "../../../hooks/v4/useAlertSessionError"
import useAuthorizer from "../../../hooks/v4/useAuthorizer"

const alertSessionElement = document.getElementById("alert-session")

const AlertSession = () => {
  const { showAlertSessionError } = useContext(GlobalContext)
  const isOpen = showAlertSessionError.state
  const { loginWithRedirect } = useAuth0()
  const { hideAlert } = useAlertSessionError()
  const { logout } = useAuthorizer()

  const on_submit = () => {
    hideAlert()
    logout()
    setTimeout(() => loginWithRedirect(), 500)
  }

  if (!alertSessionElement) return null
  return createPortal(
    isOpen ? (
      <Fragment>
        <div className="cont">
          <div className="alert">
            <img src={logo_entel} alt="logo" />
            <h3>¡Tu sesión ha expirado!</h3>
            <p>Por favor, vuelve a iniciar sesión.</p>
            <button onClick={on_submit}>Iniciar sesión</button>
          </div>
        </div>
        <style jsx>{`
          .cont {
            position: fixed;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.65);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999999;
            box-sizing: border-box;
          }
          .alert {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
            background-color: white;
            padding: 2rem;
            border-radius: 1rem;
            text-align: center;
          }
          .alert img {
            width: 150px;
          }
          .alert button {
            background-color: #002eff;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
          .alert button:hover {
            background-color: #4f6efc;
          }
        `}</style>
      </Fragment>
    ) : null,
    alertSessionElement,
  )
}

export default AlertSession
