import { post_middleware_oim } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAlertSessionError from "./useAlertSessionError"

const usePostMiddlewareOIM = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { showAlert } = useAlertSessionError()

  const post_middleware_oim_list_item_action = async (body: any) => {
    try {
      return await post_middleware_oim("/inventory/item/all", body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      if ([401].includes(error?.response?.status)) return showAlert()
    }
  }

  return {
    post_middleware_oim_list_item_action,
  }
}

export default usePostMiddlewareOIM
