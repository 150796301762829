import { useState, Fragment } from "react"
import { addDays } from "date-fns"
import {contentCalendarStyle} from "overrides/theme/entel/base/components/Core/Order/FilterDate/ContentCalendar/ContentCalendar.style"
import ButtonOptionCalendar from "overrides/theme/entel/base/components/Core/Order/FilterDate/ContentCalendar/ButtonOptionCalendar"
import DateRangeCalendar from "overrides/theme/entel/base/components/Commons/Calendar/DateRangeCalendar"
import { IconCheck } from "theme/common/Icon.styled"
import { IContentCalendar, IRangeExtend } from "interfaces/IContentCelendar"
import { FilterOptionsCalendarEnum } from "enums/FilterOptionsCalendarEnum"
import { rangeDefault } from "helpers/InitStateGeneral"

const ContentCalendar = ({ closeModal, onChangeRangeDate, initRange }: IContentCalendar) => {
  const [range, setRange] = useState<IRangeExtend>(initRange || rangeDefault)

  //events
  const handleClickToday = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: new Date(),
      endDate: new Date(),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickYesterday = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickLastWeek = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickFilter = () => {
    closeModal && closeModal()

    onChangeRangeDate(range)
  }

  return (
    <Fragment>
      <section className="content_calendar__container">
        <div className="content_calendar__content_option_calendar">
          <ButtonOptionCalendar
            text="Hoy"
            active={range.activeOptionFilter === FilterOptionsCalendarEnum.today}
            onclick={handleClickToday}
            id={FilterOptionsCalendarEnum.today}
          />
          <ButtonOptionCalendar
            text="Ayer"
            active={range.activeOptionFilter === FilterOptionsCalendarEnum.yesteday}
            onclick={handleClickYesterday}
            id={FilterOptionsCalendarEnum.yesteday}
          />
          <ButtonOptionCalendar
            text="Últimos 7 días"
            active={range.activeOptionFilter === FilterOptionsCalendarEnum.lastWeek}
            onclick={handleClickLastWeek}
            id={FilterOptionsCalendarEnum.lastWeek}
          />
        </div>
        <div className="content_calendar__content_calendar_body">
          <DateRangeCalendar range={range} setRange={setRange} />
          <button
            className="content_calendar__content_calendar_submit_button"
            onClick={handleClickFilter}
          >
            <IconCheck />
            <span>Aplicar</span>
          </button>
        </div>
      </section>
      <style jsx>{contentCalendarStyle}</style>
    </Fragment>
  )
}

export default ContentCalendar
