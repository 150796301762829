import { Fragment, FunctionComponent, useEffect, useContext, useState } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { useParams } from "react-router-dom"
import { adminSourceScreenStyle } from "overrides/theme/entel/base/pages/AdminDeliveriesSourcesScreen/AdminDeliveriesSourcesScreen.style"
// icons
import { AuthContext } from "context/context"
import { FaSave } from "react-icons/fa"
import LabelSwitch from "overrides/theme/entel/base/components/Commons/LabelSwitch/LabelSwitch"
import useSourcesResources from "overrides/theme/entel/base/hooks/v4/useSourcesResources"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import logo_omnix from "assets/images/logo-omnix3.png"
import classNames from "classnames"
import Switch from "overrides/theme/entel/base/components/Custom/Switch/Switch"

const Chip: React.FC<any> = ({ enabled, label, onChange }) => {
  return (
    <div className="w-auto flex flex-row items-center justify-center">
      <Switch label={label} onChange={onChange} checked={enabled} />
    </div>
  )
}

const AdminDeliveriesSourcesScreen: FunctionComponent<any> = () => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { notification, displayHeaderRouteName } = useContext(GlobalContext)
  const { userId }: any = useParams()
  const [allStores, setAllStores] = useState<any>({
    id: "ALLSOURCES",
    name: "Todas las tiendas/bodegas",
    checked: true,
  })
  const [sourceTyped, setSourceTyped] = useState<string>("")
  const [stores, setStores] = useState<any>([])
  const [modifiedStores, setModifiedStores] = useState([])
  const [loading, set_loading] = useState(true)
  const user_has_all_sources = Boolean(user?.currentSources.some((src: string) => src === "ALL"))
  const { put_upsert_schedule_logistic_source_action, post_list_source_combined_action } = useSourcesResources()

  useEffect(() => {
    const request = async () => {
      let filters: any = {}
      if (Boolean(!user_has_all_sources)) {
        Object.assign(filters, { id: user?.currentSources })
      }
      const res: any = await post_list_source_combined_action({
        body: {
          skipRecords: 0,
          maxRecords: 1000,
        },
      })
      setStores(
        (res.data?.message?.records || []).map((source: any) => ({
          id: source.id,
          name: source.name,
          enabled: source.enabled,
          schedule: source.schedule,
        })),
      )
      set_loading(false)
    }
    request()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    displayHeaderRouteName.dispatch(`Control métodos de envío`)
    //eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    if (Boolean(stores.length) && Boolean(stores.some((src: any) => !Boolean(src.enabled)))) {
      setAllStores({ ...allStores, enabled: false })
    }
    if (Boolean(stores.every((src: any) => Boolean(src.enabled)))) {
      setAllStores({ ...allStores, enabled: true })
    }
    //eslint-disable-next-line
  }, [stores])

  const updateStoreState = (updatedStore: any) => {
    setModifiedStores((prev: any) =>
      prev.some((store: any) => store.id === updatedStore.id)
        ? prev.map((store: any) => (store.id === updatedStore.id ? updatedStore : store))
        : [...prev, updatedStore],
    )
  }

  const toggleDeliveryDisabled = (storeId: string, deliveryType: string) => {
    setStores((prev: any) =>
      prev.map((store: any) => {
        if (store.id === storeId) {
          const updatedSchedule = store.schedule.map((item: any) => {
            if (item.deliveryType === deliveryType) {
              const updatedItem = {
                ...item,
                disabled: !item.disabled,
              }
              return updatedItem
            }
            return item
          })

          const updatedStore = { ...store, schedule: updatedSchedule }
          updateStoreState(updatedStore)
          return updatedStore
        }
        return store
      }),
    )
  }

  const on_submit = async () => {
    try {
      set_loading(true)
      let _data: any = modifiedStores.map((src: any) => ({
        id: src?.id,
        schedule: src?.schedule.map((sch: any) => ({
          deliveryType: sch?.deliveryType,
          disabled: Boolean(sch?.disabled),
          shippingType: sch?.shippingType || [],
        })),
      }))
      let res: any = await put_upsert_schedule_logistic_source_action(_data)
      if (res?.data?.code < 400) {
        notification.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            type: "success",
            title: "Tiendas/bodegas actualizadas",
            message: "",
          },
        })
      }
      set_loading(false)
    } catch (error) {
      set_loading(false)
    }
  }

  const filterSources = (query: string) => {
    try {
      const regex = new RegExp(query, "i")
      return stores?.filter((src: any) => regex.test(src.name) || regex.test(src.id))
    } catch (error) {
      return []
    }
  }

  const sources_to_show: any = sourceTyped ? filterSources(sourceTyped) : stores

  return (
    <Fragment>
      {loading && <Loading defaultOpened={true} />}
      <div className="admin_source_screen__wapper_container">
        <div className="admin_source_screen__container">
          <div className="admin_source_screen__body">
            <div className="ass__body_header">
              <h4 className="dus__info_name">Tiendas/Bodegas asignadas</h4>
              <button
                className="admin_source_screen__body__button_submit"
                onClick={on_submit}
                disabled={Boolean(!modifiedStores?.length)}
              >
                <p>Guardar</p>
                <FaSave size={"1rem"} />
              </button>
            </div>
            <div className="ass__body_search_cont">
              <input
                type="text"
                className="admin_source_screen__input_form"
                placeholder="Id de tienda/bodega"
                disabled={Boolean(userId)}
                onChange={(e: any) => setSourceTyped(e.target.value)}
                value={sourceTyped}
                autoFocus
              />
            </div>
            <div className="ass__body_sources_cont">
              <div className="admin_source_screen__container_sources">
                {Boolean(sources_to_show?.length && !sourceTyped) ? (
                  <div
                    style={{ display: "none" }}
                    className="admin_source_screen__content-checked admin_source_screen__content-checked-title"
                  >
                    <LabelSwitch
                      id="source--ALLSOURCES"
                      value="ALLSOURCES"
                      checked={allStores.enabled}
                      onChange={() => {
                        setAllStores({ ...allStores, enabled: !allStores.enabled })
                        setStores(stores.map((srcs: any) => ({ ...srcs, enabled: !allStores.enabled })))
                      }}
                      label="Habilitar todas las tiendas/bodegas"
                    />
                  </div>
                ) : null}
                <div className="admin_source_screen__container_list_sources">
                  {Boolean(sources_to_show?.length) ? (
                    sources_to_show.map((source: any, i: number) => (
                      <div key={i} className="admin_source_screen__container_list_sources__box_item">
                        <div className="admin_source_screen__container_list_sources__subbox_item">
                          <h4 className="font-semibold">{`${source.name} (${source.id})`}</h4>
                          <p
                            className={classNames("text-sm italic", {
                              "text-green-500": source.enabled,
                              "text-red-500": !source.enabled,
                            })}
                          >
                            Tienda {source.enabled ? "Activa" : "Inactiva"}
                          </p>
                        </div>
                        <div className="w-full flex flex-row items-start justify-start flex-wrap gap-6">
                          {source?.schedule?.map((_sch: any, _ii: number) => {
                            return (
                              <Chip
                                key={_ii}
                                id={`source_sch--${source.id}--${_sch.deliveryType}`}
                                value={source.id}
                                onChange={() => toggleDeliveryDisabled(source.id, _sch.deliveryType)}
                                enabled={!_sch?.disabled}
                                label={`${_sch?.deliveryType}`}
                              />
                            )
                          })}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>
                      {sourceTyped
                        ? `No se encontró la tienda/bodega '${sourceTyped}'`
                        : "No hay tiendas/bodegas asignadas"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="admin_source_screen__footer">
          <p>Powered by</p>
          <img src={logo_omnix} alt="logo" className="logo_omnix" />
        </div>
      </div>
      <style jsx>{adminSourceScreenStyle}</style>
    </Fragment>
  )
}

export default AdminDeliveriesSourcesScreen
