import { useEffect, useContext, useState, useRef, MouseEvent, FunctionComponent } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { reportsScreenStyle } from "overrides/theme/entel/base/pages/ReportScreens/ReportScreen.style"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"
import Paginate from "overrides/theme/entel/base/components/Commons/Pagination/Paginate"
import Modal from "components/Commons/Modal/Modal"
import ContentCalendar from "overrides/theme/entel/base/components/Core/Order/FilterDate/ContentCalendar/ContentCalendar"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import { IconArrowDownMain, IconCalendar, IconClose, IconReload } from "theme/common/Icon.styled"
import { IRangeExtend } from "interfaces/IContentCelendar"
import Swal from "sweetalert2"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import cn from "classnames"
import { addDays } from "date-fns"

const ReportScreen: FunctionComponent = () => {
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const [dateRange, setDateRange] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [template, setTemplate] = useState<any>(null)
  const { get_middleware_query_action, post_middleware_query_action } = usePostMiddlewareOsrm()

  const refElemReports = useRef<HTMLDivElement>(null)
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  useEffect(() => {
    displayHeaderRouteName.dispatch("Reportes")
  }, [displayHeaderRouteName])

  const getReports = async () => {
    setLoading(true)
    try {
      const response = await get_middleware_query_action("/reports", { from: page * 10, size: 10, reportType: "daily_sales"  })
      setData(response?.data?.message?.records || [])
      setPages(response?.data?.message?.pages)
      check_if_any_report_is_processing(response?.data?.message?.records || []) && setTimeout(() => getReports(), 15000)
    } finally {
      setLoading(false)
    }
  }

  const check_if_any_report_is_processing = (_reports: any) => {
    return Boolean(_reports?.some((report: any) => report?.status?.toLocaleUpperCase() === "PROCESSING"))
  }

  useEffect(() => {
    getReports()
    //eslint-disable-next-line
  }, [page])

  useEffect(() => {
    const get_templates = async () => {
      const response = await get_middleware_query_action("/templates", { from: 0, size: 30 })
      const ruteo_template = response?.data?.message?.records?.find((template: any) => template.reportType === "daily_sales")
      setTemplate(ruteo_template)
    }
    get_templates()
    //eslint-disable-next-line
  }, [])

  const handleClickPage = (pageSelected: number) => setPage(pageSelected)

  const handleClickCalendar = () => refModal.current?.open()

  const handleChangeRangeDate = (range: IRangeExtend) => setDateRange(range)

  const handleClickClearFilter = (e: MouseEvent<SVGAElement>) => {
    e.stopPropagation()
    setDateRange(undefined)
  }

  const generate_report = async () => {
    setLoading(true)
    try {
      const body = {
        templateId: template?.id,
        search: {
          shippingGroup: {
            filters: {
              orderCreationDate: {
                from: dateRange?.startDate
                  ? formatDate(dateRange?.startDate, "yyyy-MM-dd")
                  : formatDate(addDays(new Date(), -5), "yyyy-MM-dd"),
                to: dateRange?.endDate
                  ? formatDate(dateRange?.endDate, "yyyy-MM-dd")
                  : formatDate(new Date(), "yyyy-MM-dd"),
              },
            },
          },
          order: { filters: {} },
          item: { filters: {} },
        },
      }
      const response: any = await post_middleware_query_action("/reports", body)
      console.log("response", response)
      if (response?.status < 400) {
        Swal.fire({
          title: "Generando Informe",
          text: "El informe se está generando",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        page === 0 ? getReports() : setPage(0)
      } else {
        Swal.fire({
          title: "Error",
          text: "Ocurrió un error al generar el informe",
          icon: "error",
          confirmButtonText: "Aceptar",
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const get_classname_status = (status: string): string => {
    switch (status) {
      case "processed":
        return "label-success"
      case "failed":
        return "label-danger"
      default:
        return "label-warning"
    }
  }

  const get_translated_status = (status: string): string => {
    switch (status) {
      case "processed":
        return "Generado"
      case "failed":
        return "Error"
      default:
        return "Generando"
    }
  }

  console.log("range", dateRange)

  return (
    <>
      {loading && <Loading defaultOpened={true} />}
      <div className="reports_screen__wapper_container">
        <div className="reports_screen__container">
          <div className="reports_screen__body">
            <div className="reports_screen__cont-info">
              <h2>Informe Venta del Día</h2>
            </div>
            <div className="reports_screen__cont-filters">
              <h4>Filtros</h4>
              <div className="reports__filters-container">
                <p>Fecha de creación:</p>
                <div className="reports__filter_date__container">
                  <div className="reports__filter_date__content_date" onClick={handleClickCalendar}>
                    <IconCalendar className="reports__filter_date__calendar" />
                    <div className="reports__filter_date__info_date">
                      {dateRange ? (
                        <>
                          <span>
                            {`${dateRange && formatDate(dateRange?.startDate, "yyyy-MM-dd")} / ${formatDate(
                              dateRange?.endDate,
                              "yyyy-MM-dd",
                            )}`}
                          </span>
                          <IconClose onClick={handleClickClearFilter} />
                        </>
                      ) : (
                        <>
                          <span>Seleccione...</span>
                          <IconArrowDownMain />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="reports__filter_search__container">
                  <button disabled={Boolean(!template?.id)} onClick={generate_report}>
                    Generar Informe Venta del Día
                  </button>
                </div>
              </div>
            </div>
            <div className="reports_screen__cont-table">
              <div className="reports_screen__cont-table__refresh">
                <button onClick={getReports}>
                  <IconReload />
                  <p>Actualizar</p>
                </button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Tipo</th>
                    <th>Fecha de generación</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({ id, reportType, date, status, url }) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{reportType}</td>
                      <td>{`${formatDate(date, "yyyy-MM-dd")}, ${formatTime(date)}`}</td>
                      <td>
                        <p
                          className={cn("", {
                            [get_classname_status(status)]: true,
                          })}
                        >
                          {get_translated_status(status)}
                        </p>
                      </td>
                      <td>
                        {status === "processed" ? (
                          <a href={url} target="_blank" rel="noreferrer">
                            Descargar
                          </a>
                        ) : (
                          <p
                            className={cn("", {
                              [get_classname_status(status)]: true,
                            })}
                          >
                            {status === "failed" ? "No disponible" : "Generando"}
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="reports_screen__cont-pagination">
              <Paginate
                pageCount={pages}
                onChangePage={handleClickPage}
                elementFocus={refElemReports}
                pageInit={page}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{reportsScreenStyle}</style>
      <Modal ref={refModal}>
        <ContentCalendar
          closeModal={refModal.current?.close}
          onChangeRangeDate={handleChangeRangeDate}
          initRange={dateRange}
        />
      </Modal>
    </>
  )
}

export default ReportScreen
