import { useState, useContext } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "context/context"
import { useCancelToken } from "hooks"
import { updateShippingGroupStateCustom } from "overrides/theme/entel/base/services/ShippingGroupService"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { put_middleware_base } from "services/Middleware"

const useUpdateSGCustom = () => {
  const { t } = useTranslation()
  const { shippingtype, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const { isCancel } = useCancelToken()
  const { notification, errorHander } = useContext(GlobalContext)
  const [loading, set_loading] = useState(false)

  const onUpdateSGActionCustom = async (body: any, action: any) => {
    try {
      set_loading(true)
      await updateShippingGroupStateCustom(body)
      set_loading(false)
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Orden modificada exitosamente!",
          title: `${t(`Acción aplicada`)}: ${t(`${action.action}`)}`,
          type: "success",
        },
      })
      setTimeout(() => {
        history.push(`/order/${shippingtype}/${action.next_page}/${shippingGroupId}/${id}`)
        window.location.reload()
      }, 1000)
    } catch (error: any) {
      if (isCancel(error)) return
      if (error.response?.status === 401)
        return errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  const onUpdateSGActionCustomBase = async (sgId: string, data: any) => {
    try {
      const response = await put_middleware_base(
        `/update/${sgId}`,
        { entityType: "ShippingGroup", entityId: sgId, data },
        // newCancelToken(),
      )
      return response
    } catch (error: any) {
      if (isCancel(error)) return
      if (error.response?.status === 401)
        return errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  return {
    loading,
    onUpdateSGActionCustom,
    onUpdateSGActionCustomBase,
  }
}

export default useUpdateSGCustom
