import { useTranslation } from "react-i18next"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import { getActionTranslateValue, getStatusTranslateValue } from "overrides/theme/entelperu/base/helpers/status_helper"
import { useParams } from "react-router-dom"

const OrderHistorialExecuterActions = (props: any) => {
  const { t } = useTranslation()
  const { shippingtype }: any = useParams()
  const { data } = props

  const states = data?._sg?._omnixStateMachine?.states || []

  const template = states.length && (
    <article className="order__traces">
      <h2>{t("Historial de ejecución de estados")}</h2>
      <section>
        {states?.map((state: any, index: number) => (
          <article className="order__traces__container" key={index}>
            <h4 className="order__traces__title">
              <span>{getStatusTranslateValue(state?.state?.name, shippingtype.toUpperCase())?.title}</span>
              {/* <span>{formatDate(item?.date)}</span> */}
            </h4>
            <div className="order__traces__body">
              {/* <h5>{t("Descripción")}</h5>
              <p className="order__traces__body__text order__traces__body__text--description">{item?.description}</p> */}
              <h5>{t("Acción")}</h5>
              <p className="order__traces__body__text order__traces__body__text--description">
                {getActionTranslateValue(state?.action?.name)}
                {/* {getStatusTranslateValue(item?.state, shippingtype.toUpperCase())?.title} */}
              </p>

              <h5>{t("Información adicional")}</h5>
              {state?.state?.custom?.executer ? (
                <>
                  <p className="order__traces__body__text order__traces__body__text--custom-key">
                    <span>Realizada por:</span>{" "}
                    <span>{`${state?.state?.custom?.executer?.name} (${state?.state?.custom?.executer?.id})`}</span>
                  </p>
                  <p className="order__traces__body__text order__traces__body__text--custom-key">
                    <span>Fecha:</span> <span>{`${formatDate(state?.date)}, ${formatTime(state?.date)}`}</span>
                  </p>
                </>
              ) : null}
            </div>
          </article>
        ))}
      </section>
    </article>
  )

  return template || <></>
}

export default OrderHistorialExecuterActions
