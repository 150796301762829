import { post_middleware_osrm, get_middleware_osrm } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAlertSessionError from "./useAlertSessionError"

const usePostMiddlewareOsrm = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { showAlert } = useAlertSessionError()

  const post_middleware_query_action = async (endpoint: string, body: any) => {
    try {
      return await post_middleware_osrm(endpoint, body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      if ([401].includes(error?.response?.status)) return showAlert()
    }
  }

  const get_middleware_query_action = async (endpoint: string, params: any) => {
    try {
      return await get_middleware_osrm(endpoint, params, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
    }
  }

  return {
    post_middleware_query_action,
    get_middleware_query_action,
  }
}

export default usePostMiddlewareOsrm
