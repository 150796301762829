export const OrderDetailStyled = `
  .page__order {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 2rem;
    padding: 1.8rem;
    margin: 1.2rem 0;
    padding-bottom: 7%;
    position: relative;
  }
  .order__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .order__main__detail {
    width: 100%;
    padding-top: 2rem;
  }
  .order__main__detail h2 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px
  }
  .order__main__detail__summary {
    background-color: #FFFFFF;
    border: 1px solid green;
    border: 1px solid #DDDDE9;
    box-shadow: 0px 6px 24px rgb(207 195 172 / 20%);
    border-radius: 12px;
    font-family: "Poppins", Arial, sans-serif;
    margin-top: 1.5rem;
    padding: 1.4rem 1.7rem;
    transition: all 1s;
    display: grid;
    grid-template-columns: 1fr;
  }
  .order__main__detail__summary  h3 {
    color: var(--color-blue-dark);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 24px;
    margin-bottom: 0.7rem;
    margin-top: 0.2rem;
  }
  .order__main__detail__summary  h4 {
    color: var(--color-main-gray);
    letter-spacing: 0.01em;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.5rem;
  }
  .order__main__detail__summary  p {
    color: var(--color-main-gray);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .order__main__detail__folio__url {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  .order__main__detail__summary  p strong {
    font-weight: 600;
  }
  .order__main__detail__title_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
  }
  .order__main__detail__summary > div:not(.order__main__detail__action) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  .order__main__detail__action {
    justify-self: end;
    margin-top: 2rem;
  }
  .order__main__detail__summary .order__main__detail__action__cancel {
    background: #E5E5E5;
    color: var(--color-main-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order__main__detail__action__action {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: linear-gradient(171.14deg, #002eff 3.14%, #002eff 20.68%, #002eff 103.69%);
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
    margin-left: 0.5rem;
  }
  .order__products, .order__products_list__grid {
    font-family: "Poppins", Arial, sans-serif;
  }
  .order__products h2 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .order__products__list {
  }
  .order__products__list__item {
    min-width: fit-content;
    margin-top: 15px;
  }
  .order__products__list__item__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #DDDDE9;
    border-radius: 12px;
    padding: 1rem 1.2rem;
    box-sizing: border-box;
  }
  .order__products__list__item__description {
    width: 100%;
    display: grid;
    grid-template-columns: 120px auto;
    // border: 1px solid #DDDDE9;
  }
  .order__products__list__item__cont_series {
    width: 100%;
    padding: 1rem;
  }
  .order__products__list__item__image {
    justify-self: center;
    align-self: center;
  }
  .order__products__list__item__details h4 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .order__products__list__item__details > div {
    display: flex;
  }
  .order__products__list__item__details > div > p {
    color: var(--color-main-gray);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-right: 5px;
  }
  .order__products__list__item__details > div > p > strong {
    font-weight: 600;
  }

  /* Traces */
  .order__traces {

  }
  .order__traces h2 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    margin-top: 2.8rem;
    margin-bottom: 1.5rem;
  }
  .order__traces > section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    display: grid;
  }
  .order__traces h4 {
    border-bottom: 1px solid #DDDDDD;
    color: #23203F;
    display: grid;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.9rem;
    grid-template-columns: 1fr auto;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }
  .order__traces h4.order__traces__title span:nth-child(1) {
    color: var(--color-orange);
    font-family: 'Montserrat';
  }
  .order__traces h4.order__traces__title span:nth-child(2) {
    font-family: 'Montserrat';
    font-size: 0.7rem;
    color: var(--color-main-gray);
    text-align: right;
    line-height: 0.7rem;
    position: relative;
    top: 5px;
  }
  .order__traces .order__traces__body h5{
    color: var(--color-main-gray);
    font-family: 'Montserrat';
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 0.8rem;
    margin-top: 1.5rem;
    text-transform: uppercase;
  }
  .order__traces .order__traces__body p {
    font-family: 'Montserrat';
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .order__traces .order__traces__container {
    border-radius: 1rem;
    box-shadow: 0px 4px 14px rgb(207 195 172 / 20%);
    border: 1px solid #DDDDE9;
    transition: all 0.5s ease-in-out;
    padding: 1rem;
  }
  .order__traces__body__text--custom-key span:first-child {
    font-weight: 500;
    text-transform: capitalize;
  }
  .show_desktop_and_hide_mobile {
    width: 100%;
    display: none;
  }
  .show_mobile_and_hide_desktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .order__main__detail__button_reload_info {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .order__main__detail__button_reload_info svg {
    font: 1.2rem;
  }
  .order__main__detail__spinner_data {
    animation: rotate 3s linear infinite;
    font-size: 1.2rem;
    color: #002eff;
  }
  .cont__order-detail__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    gap: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    color: #716f87;
  }
  .cont__order-detail__footer .logo_omnix {
    width: 8rem;
  }
  @media screen and (min-width: 1280px) {
    .order__products__list__item__wrapper {
      width: 100%;
      display: flex;
      flex-direction:row;
    }
    .order__products__list__item__description {
      width: 50%;
      display: grid;
      grid-template-columns: 120px auto;
      padding: 1.2rem 1.5rem;
      padding-left: 1rem;
    }
    .order__products__list__item__cont_series {
      width: 50%;
    }
    .show_mobile_and_hide_desktop {
      display: none;
    }
    .show_desktop_and_hide_mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
    .order__products__list__item {

    }
    .order__main {
      flex-direction: row;
    }
    .order__main__detail {
      width: 100%;
      padding-top: 0rem;
    }
  }

  @media screen and (min-width: 992px) {
    .order__traces > section {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 1rem;
      display: grid;
    }
  }
`
