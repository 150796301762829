import translatesObj from "overrides/theme/entelperu/base/translates/translation.json"

let statusses: any = translatesObj

export const getStatusTranslateValue = (statusKey: string, _shippingType: string) => {
  if (statusses.STATUS[_shippingType] && statusses.STATUS[_shippingType][statusKey]) {
    return statusses.STATUS[_shippingType][statusKey]
  }
  if (statusses.STATUS.default && statusses.STATUS.default[statusKey]) {
    return statusses.STATUS.default[statusKey]
  }
  return statusKey
}

export const getActionTranslateValue = (actionKey: string) => {
  if (statusses[actionKey]) {
    return statusses[actionKey]
  }
  return actionKey
}