import { post_middleware_state_machines_types } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAlertSessionError from "./useAlertSessionError"
// import useAuthorizer from "./useAuthorizer"

interface IPostStateMachinesTypes {
  type: "Order" | "ShippingGroup"
  shippingType: string
}

const usePostMiddlewareStateMachineTypes = ({ type, shippingType }: IPostStateMachinesTypes) => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { showAlert } = useAlertSessionError()
  // const { logout } = useAuthorizer()

  const body_order = {
    type: shippingType === "HD" ? "entel-order-hd-flow" : "entel-order-sp-flow",
    entityType: type,
  }

  const body_sg = {
    type: shippingType === "HD" ? "entel-shipping-group-flow-hd" : "entel-shipping-group-sp-flow",
    entityType: type,
  }

  const post_middleware_state_machine_schema = async () => {
    try {
      let body = type === "Order" ? body_order : body_sg
      return await post_middleware_state_machines_types([body], newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
    }
  }

  return {
    post_middleware_state_machine_schema,
  }
}

export default usePostMiddlewareStateMachineTypes
