import { useContext } from "react"
import { authorizerService } from "services/Middleware"
import Logger from "classes/Logger"
import { AuthContext } from "context/context/AuthContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { Error_GNERAL, ERROR_LOGIN } from "helpers/constHelper"
import JsonEngine from "classes/JsonEngine"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
// import { useCancelToken } from "hooks"
import useAuth0Credentials from "overrides/theme/entel/base/hooks/v4/useAuth0Credentials"
// import { useAuth0 } from "@auth0/auth0-react"

const useAuthorizer = () => {
  const {
    dispatch,
    state: { error, loading },
  } = useContext(AuthContext)
  // const { newCancelToken } = useCancelToken()
  const { clientId, audience } = useAuth0Credentials()
  // const { logout: logout_auth0 } = useAuth0()

  const loginUser = async () => {
    try {
      dispatch({ type: "[auth] Login start" })

      // Temporal handler
      const credentials = {
        audience: audience,
        grant_type: "client_credentials",
        client_id: clientId,
      }

      const responseLogin = await authorizerService(credentials)

      const {
        data: { access_token },
      } = responseLogin

      dispatch({ type: "[auth] Set menu user", payload: [{ statusOrder: EShippinggroupStatus.all }] })

      dispatch({
        type: "[auth] Login success",
        payload: {
          id: "guest",
          email: "guest",
          groups: [{ id: "guest", mainGroup: "nn" }],
          mainGroup: {
            id: "guest",
            name: "guest",
          },
          name: "Invitado",
          role: "guest",
          token: access_token,
          currentSources: ["guest"],
        },
      })
    } catch (error: any) {
      Logger.error(error)

      if (error.response?.status === TransactionCodeEnum.unauthorized)
        dispatch({ type: "[auth] Login error", payload: ERROR_LOGIN })
      else dispatch({ type: "[auth] Login error", payload: Error_GNERAL })
    }
  }

  //Send to the context the log out action and clean the user information in the local storage
  const logout = () => {
    // history.push("/logout")
    dispatch({ type: "[auth] Logout" })
    // logout_auth0()
    JsonEngine.clear()
  }

  return {
    loading,
    error,
    loginUser,
    logout,
  }
}

export default useAuthorizer
