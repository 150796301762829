import React, { useState, useEffect } from "react"
import { FiChevronUp, FiChevronDown } from "react-icons/fi"
import { timeSelectorStyle } from "components/Commons/TimeSelector/TimeSelector.style"

interface TimeSelectorProps {
  value?: string
  onChange?: (time: string) => void
  className?: string
  is24Hour?: boolean
}

const TimeSelector: React.FC<TimeSelectorProps> = ({ value = "", onChange, className = "", is24Hour = false }) => {
  const now = new Date()
  const initialHour = now.getHours()
  const initialMinute = Math.floor(now.getMinutes() / 5) * 5

  const [hour, setHour] = useState(is24Hour ? initialHour : initialHour % 12 || 12)
  const [minute, setMinute] = useState(initialMinute)
  const [period, setPeriod] = useState(initialHour >= 12 ? "PM" : "AM")

  // Parse value if provided
  useEffect(() => {
    if (value) {
      const [hourStr, minuteStr] = value.split(":")
      let hourVal = parseInt(hourStr, 10)
      const minuteVal = parseInt(minuteStr, 10)

      if (!isNaN(hourVal) && !isNaN(minuteVal)) {
        if (!is24Hour && hourVal > 12) {
          setPeriod("PM")
          hourVal = hourVal - 12
        } else if (!is24Hour && hourVal === 0) {
          setPeriod("AM")
          hourVal = 12
        } else if (!is24Hour) {
          setPeriod(hourVal === 12 ? "PM" : "AM")
        }

        setHour(hourVal)
        setMinute(minuteVal)
      }
    }
  }, [value, is24Hour])

  // Emit changes
  useEffect(() => {
    if (onChange) {
      let hourValue = hour

      if (!is24Hour) {
        if (period === "PM" && hour < 12) {
          hourValue = hour + 12
        } else if (period === "AM" && hour === 12) {
          hourValue = 0
        }
      }

      const formattedTime = `${String(hourValue).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
      onChange(formattedTime)
    }
  }, [hour, minute, period, onChange, is24Hour])

  const incrementHour = () => {
    setHour((prev) => {
      const max = is24Hour ? 23 : 12
      const min = is24Hour ? 0 : 1
      return prev >= max ? min : prev + 1
    })
  }

  const decrementHour = () => {
    setHour((prev) => {
      const max = is24Hour ? 23 : 12
      const min = is24Hour ? 0 : 1
      return prev <= min ? max : prev - 1
    })
  }

  const incrementMinute = () => {
    setMinute((prev) => {
      const newMinute = prev + 5
      return newMinute >= 60 ? 0 : newMinute
    })
  }

  const decrementMinute = () => {
    setMinute((prev) => {
      const newMinute = prev - 5
      return newMinute < 0 ? 55 : newMinute
    })
  }

  const togglePeriod = () => {
    setPeriod((prev) => (prev === "AM" ? "PM" : "AM"))
  }

  return (
    <div className={`time-selector ${className}`}>
      <div className="time-display">
        <div className="time-unit-container">
          <button className="time-control-btn" onClick={incrementHour}>
            <FiChevronUp />
          </button>
          <div className="time-unit">{String(hour).padStart(2, "0")}</div>
          <button className="time-control-btn" onClick={decrementHour}>
            <FiChevronDown />
          </button>
        </div>
        <div className="time-separator">:</div>
        <div className="time-unit-container">
          <button className="time-control-btn" onClick={incrementMinute}>
            <FiChevronUp />
          </button>
          <div className="time-unit">{String(minute).padStart(2, "0")}</div>
          <button className="time-control-btn" onClick={decrementMinute}>
            <FiChevronDown />
          </button>
        </div>
        {!is24Hour && (
          <button className={`period-toggle ${period.toLowerCase()}`} onClick={togglePeriod}>
            {period}
          </button>
        )}
      </div>
      <style jsx>{timeSelectorStyle}</style>
    </div>
  )
}

export default TimeSelector
