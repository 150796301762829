import { post_middleware_update_multiple_sgs } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAlertSessionError from "./useAlertSessionError"
// import useAuthorizer from "./useAuthorizer"

interface IUpdateSgsBody {
  action: string,
  entities: Array<{
    id: string,
    custom?: any
  }>
}

const useUpdateMultipleSgs = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { showAlert } = useAlertSessionError()
  // const { logout } = useAuthorizer()

  const post_update_multiple_sgs_action = async (body: IUpdateSgsBody) => {
    try {
      return await post_middleware_update_multiple_sgs(body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      // logout()
    }
  }

  // const parsed_response_sgs = (_data: any) => {
  // }

  return {
    post_update_multiple_sgs_action,
  }
}

export default useUpdateMultipleSgs
