import { useState, Fragment } from "react"
import { addDays } from "date-fns"
import { contentCalendarStyle } from "overrides/theme/entel/base/components/Core/Order/FilterDate/ContentCalendar/ContentCalendar.style"
import ButtonOptionCalendar from "overrides/theme/entel/base/components/Core/Order/FilterDate/ContentCalendar/ButtonOptionCalendar"
import DateCalendar from "overrides/theme/entel/base/components/Commons/Calendar/DateCalendar/DateCalendar"
import { IconCheck } from "theme/common/Icon.styled"
import { IContentDateCalendar } from "interfaces/IContentCelendar"
import { FilterOptionsCalendarEnum } from "enums/FilterOptionsCalendarEnum"
import TimeSelector from "components/Commons/TimeSelector/TimeSelector"

const ContentCalendar = ({ closeModal, onChangeDate, initDate, hasTime }: IContentDateCalendar) => {
  const [date, setDate] = useState<Date>(initDate || new Date())
  const [time, setTime] = useState<string>("")
  const [activeOptionFilter, setActiveOptionFilter] = useState<FilterOptionsCalendarEnum>(
    FilterOptionsCalendarEnum.today,
  )

  //events
  const handleClickToday = () => {
    setDate(new Date())
    setActiveOptionFilter(FilterOptionsCalendarEnum.today)
  }

  const handleClickTomorrow = () => {
    setDate(addDays(new Date(), 1))
    setActiveOptionFilter(FilterOptionsCalendarEnum.yesteday)
  }

  const handleClickFewDays = () => {
    setDate(addDays(new Date(), 3))
    setActiveOptionFilter(FilterOptionsCalendarEnum.lastWeek)
  }

  const handleClickFilter = () => {
    closeModal && closeModal()
    onChangeDate(date, time)
  }

  return (
    <Fragment>
      <section className="content_calendar__container">
        <div className="content_calendar__content_option_calendar">
          <ButtonOptionCalendar
            text="Hoy"
            onclick={handleClickToday}
            id={FilterOptionsCalendarEnum.today}
            active={activeOptionFilter === FilterOptionsCalendarEnum.today}
          />
          <ButtonOptionCalendar
            text="Mañana"
            onclick={handleClickTomorrow}
            id={FilterOptionsCalendarEnum.yesteday}
            active={activeOptionFilter === FilterOptionsCalendarEnum.yesteday}
          />
          <ButtonOptionCalendar
            text="En 3 días"
            onclick={handleClickFewDays}
            id={FilterOptionsCalendarEnum.lastWeek}
            active={activeOptionFilter === FilterOptionsCalendarEnum.lastWeek}
          />
        </div>
        <div className="content_calendar__content_calendar_body">
          <div className="content_calendar__content_calendar_body_date">
            <DateCalendar date={date} setDate={setDate} />
            {hasTime ? (
              <div className="content_calendar__content_calendar_body_time">
                <TimeSelector value={time} onChange={setTime} />
              </div>
            ) : null}
          </div>
          <button className="content_calendar__content_calendar_submit_button" onClick={handleClickFilter}>
            <IconCheck />
            <span>Aplicar</span>
          </button>
        </div>
      </section>
      <style jsx>{contentCalendarStyle}</style>
    </Fragment>
  )
}

export default ContentCalendar
