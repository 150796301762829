export default class Env {
  static _comment = "Information about the environment. @version 1.0.1 @author Naldo Duran<naldorck@gmail.com>"

  static clients = ["localhost", "wom", "privilege", "sandbox", "shiperto", "entelperu", "entel"]

  static children_clients = [
    {
      client: "logytech",
      children: this.clients[4],
    },
  ]

  static env = ["dev", "uat", "prod"]

  static defaults = {
    client: this.clients[5],
    env: this.env[1],
  }

  static sg_shipping_types = ["HD", "SP", "RT", "TR", "HDR"]

  static sg_shipping_types_entel = [this.sg_shipping_types[0], this.sg_shipping_types[1]]

  static sg_actions = []
  static sg_status = []
  static sg_shipping_types_wom = []
  static sg_shipping_types_privilege = []
  static sg_shipping_types_shiperto = []
  static order_status_active = []
  static order_status_inactive = []
  static sg_status_privilege = []
  static sg_status_wom = []
  static sg_status_wom_hd = []
  static sg_status_wom_sp = []
  static sg_status_wom_rt = []
  static sg_status_wom_specials = []
  static sg_status_shiperto_hd = []
  static sg_status_shiperto_hdr = []
  static sg_status_shiperto_sp = []
  static sg_status_shiperto_rt = []
  static sg_status_sandbox = []
  static sg_status_sandbox_hd = []
  static sg_status_sandbox_sp = []
  static sg_status_sandbox_rt = []
  static sg_status_sandbox_tr = []

  static sg_status_entel = [
    "ASSIGNED", // 0
    "IN_PREPARATION", // 1
    "PICKING_ID_GENERATED", // 2
    "PICKING_IN_PROGRESS", // 3
    "VALIDATE_PICKING", // 4
    "PICKING_CONFIRMED", // 5
    "PICKING_COMPLETED_PENDING_PACKAGE", // 6
    "PACKED_AND_READY_FOR_PICKUP", // 7
    "DELIVERED_TO_COURIER", // 8
    "IN_TRANSIT", // 9
    "ORDER_RECEIVED", // 10
    "DELIVERED", // 11
    "DELIVERED_IN_STORE", // 12
    "READY_FOR_PICKUP", // 13
    "PICKUP_AVAILABLE", // 14
    "SUCCESSFUL_PAYMENT", // 15
    "PICKED_UP", // 16
    "CANCELED", // 17
    "DELIVERED_IN_SOURCE", // 18
    "ORDER_RETURNED", // 19
    "DELIVER_TO_COURIER", // 20
  ]

  static sg_actions_entel = [
    "select", // 0
    "generate_picking_id", // 1
    "pick", // 2
    "validate_picking", // 3
    "picking_confirm", // 4
    "picking_complete", // 5
    "packed", // 6
    "deliver_to_courier", // 7
    "shipped", // 8
    "order_received", // 9
    "delivered", // 10
    "delivered_in_store", // 11
    "ready_for_pickup", // 12
    "pickup_available", // 13
    "successful_payment", // 14
    "picked_up", // 15
    "delivered_in_source", // 16
    "order_returned", // 17
    "cancel", // 18
  ]

  static sg_actions_entel_sp = [
    "cancel", // 0
    "select", // 1
    "generate_picking_id", // 2
    "pick", // 3
    "validate_picking", // 4
    "picking_confirm", // 5
    "picking_complete", // 6
    "packed", // 7
    "pickup_available", // 8
    "picked_up", // 9
    "order_returned", // 10
  ]

  static sg_status_entel_sp = [
    "ASSIGNED", // 0
    "IN_PREPARATION", // 1
    "PICKING_ID_GENERATED", // 2
    "PICKING_IN_PROGRESS", // 3
    "VALIDATE_PICKING", // 4
    "PICKING_CONFIRMED", // 5
    "PICKING_COMPLETED_PENDING_PACKAGE", // 6
    "PACKED_AND_READY_FOR_PICKUP", // 7
    "PICKUP_AVAILABLE", // 8
    "PICKED_UP", // 9
    "CANCELED", // 10
    "ORDER_RETURNED", // 11
  ]

  static sg_status_action_entel_hd = {
    [this.sg_status_entel[0]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[0],
        next_page: this.sg_status_entel[1].toLowerCase(),
      },
    ],
    [this.sg_status_entel[1]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[1],
        next_page: this.sg_status_entel[2].toLowerCase(),
      },
    ],
    [this.sg_status_entel[2]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[2],
        next_page: this.sg_status_entel[3].toLowerCase(),
      },
    ],
    [this.sg_status_entel[3]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[2],
        next_page: this.sg_status_entel[3].toLowerCase(),
      },
      // {
      //   action: this.sg_actions_entel[3],
      //   next_page: this.sg_status_entel[4].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[4]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[4],
      //   next_page: this.sg_status_entel[5].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[5]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[5],
      //   next_page: this.sg_status_entel[6].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[6]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[6],
        next_page: this.sg_status_entel[7].toLowerCase(),
      },
    ],
    [this.sg_status_entel[7]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[7],
        next_page: this.sg_status_entel[8].toLowerCase(),
      },
    ],
    [this.sg_status_entel[8]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[8],
        next_page: this.sg_status_entel[9].toLowerCase(),
      },
    ],
    [this.sg_status_entel[20]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[8],
        next_page: this.sg_status_entel[9].toLowerCase(),
      },
    ],
    [this.sg_status_entel[9]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      // {
      //   action: this.sg_actions_entel[9],
      //   next_page: this.sg_status_entel[10].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[16],
      //   next_page: this.sg_status_entel[18].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[10],
        next_page: this.sg_status_entel[11].toLowerCase(),
      },
    ],
    [this.sg_status_entel[10]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[11],
        next_page: this.sg_status_entel[12].toLowerCase(),
      },
    ],
    [this.sg_status_entel[11]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[12]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[12],
        next_page: this.sg_status_entel[13].toLowerCase(),
      },
    ],
    [this.sg_status_entel[13]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[13],
        next_page: this.sg_status_entel[14].toLowerCase(),
      },
    ],
    [this.sg_status_entel[14]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[14],
        next_page: this.sg_status_entel[15].toLowerCase(),
      },
    ],
    [this.sg_status_entel[15]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[15],
        next_page: this.sg_status_entel[16].toLowerCase(),
      },
    ],
    [this.sg_status_entel[16]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[17]]: [
      // {
      //   action: this.sg_actions_entel[17],
      //   next_page: this.sg_status_entel[19].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[18]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[17],
        next_page: this.sg_status_entel[19].toLowerCase(),
      },
    ],
    [this.sg_status_entel[19]]: [],
  }

  static sg_status_action_entel_sp = {
    [this.sg_status_entel_sp[0]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[1], // select
        next_page: this.sg_status_entel_sp[1].toLowerCase(), // IN_PREPARATION
      },
    ],
    [this.sg_status_entel_sp[1]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[2], // generate_picking_id
        next_page: this.sg_status_entel_sp[2].toLowerCase(), // PICKING_ID_GENERATED
      },
    ],
    [this.sg_status_entel_sp[2]]: [
      {
        action: this.sg_actions_entel_sp[3], // pick
        next_page: this.sg_status_entel_sp[3].toLowerCase(), // PICKING_IN_PROGRESS
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[3]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[4], // validate_picking
        next_page: this.sg_status_entel_sp[3].toLowerCase(), // PICKING_IN_PROGRESS
      },
    ],
    [this.sg_status_entel_sp[4]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[5], // picking_confirm
        next_page: this.sg_status_entel_sp[5].toLowerCase(), // PICKING_CONFIRMED
      },
    ],
    [this.sg_status_entel_sp[5]]: [
      {
        action: this.sg_actions_entel_sp[6], // picking_complete
        next_page: this.sg_status_entel_sp[6].toLowerCase(), // PICKING_COMPLETED_PENDING_PACKAGE
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[6]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[7], // packed
        next_page: this.sg_status_entel_sp[7].toLowerCase(), // PACKED_AND_READY_FOR_PICKUP
      },
    ],
    [this.sg_status_entel_sp[7]]: [
      {
        action: this.sg_actions_entel_sp[8], // pickup_available
        next_page: this.sg_status_entel_sp[8].toLowerCase(), // PICKUP_AVAILABLE
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[8]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[9], // picked_up
        next_page: this.sg_status_entel_sp[9].toLowerCase(), // PICKED_UP
      },
    ],
    [this.sg_status_entel_sp[9]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[10]]: [
      {
        action: this.sg_actions_entel_sp[10], // order_returned
        next_page: this.sg_status_entel_sp[10].toLowerCase(), // ORDER_RETURNED
      },
    ],
    [this.sg_status_entel_sp[11]]: [], // No transitions for ORDER_RETURNED (final state)
  }

  static sg_status_action_entelperu_sp = {
    [this.sg_status_entel_sp[0]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[1], // select
        next_page: this.sg_status_entel_sp[1].toLowerCase(), // IN_PREPARATION
      },
    ],
    [this.sg_status_entel_sp[1]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[2], // generate_picking_id
        next_page: this.sg_status_entel_sp[2].toLowerCase(), // PICKING_ID_GENERATED
      },
    ],
    [this.sg_status_entel_sp[2]]: [
      {
        action: this.sg_actions_entel_sp[3], // pick
        next_page: this.sg_status_entel_sp[3].toLowerCase(), // PICKING_IN_PROGRESS
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[3]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel[2],
        next_page: this.sg_status_entel[3].toLowerCase(),
      },
    ],
    [this.sg_status_entel_sp[4]]: [
      // {
      //   action: this.sg_actions_entel_sp[0], // cancel
      //   next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      // },
      // {
      //   action: this.sg_actions_entel_sp[5], // picking_confirm
      //   next_page: this.sg_status_entel_sp[5].toLowerCase(), // PICKING_CONFIRMED
      // },
    ],
    [this.sg_status_entel_sp[5]]: [
      {
        action: this.sg_actions_entel_sp[6], // picking_complete
        next_page: this.sg_status_entel_sp[6].toLowerCase(), // PICKING_COMPLETED_PENDING_PACKAGE
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[6]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[7], // packed
        next_page: this.sg_status_entel_sp[7].toLowerCase(), // PACKED_AND_READY_FOR_PICKUP
      },
    ],
    [this.sg_status_entel_sp[7]]: [
      {
        action: this.sg_actions_entel_sp[8], // pickup_available
        next_page: this.sg_status_entel_sp[8].toLowerCase(), // PICKUP_AVAILABLE
      },
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[8]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
      {
        action: this.sg_actions_entel_sp[9], // picked_up
        next_page: this.sg_status_entel_sp[9].toLowerCase(), // PICKED_UP
      },
    ],
    [this.sg_status_entel_sp[9]]: [
      {
        action: this.sg_actions_entel_sp[0], // cancel
        next_page: this.sg_status_entel_sp[11].toLowerCase(), // CANCELED
      },
    ],
    [this.sg_status_entel_sp[10]]: [
      {
        action: this.sg_actions_entel_sp[10], // order_returned
        next_page: this.sg_status_entel_sp[10].toLowerCase(), // ORDER_RETURNED
      },
    ],
    [this.sg_status_entel_sp[11]]: [], // No transitions for ORDER_RETURNED (final state)
  }

  // Temporal: requirement should be improved, this should be controlled by shippingtypes
  static sg_shippingtype_rt_status = []
  static sg_status_action: any = {}
  static sg_status_action_shiperto_hd = {}
  static sg_status_action_shiperto_hdr = {}
  static sg_status_action_shiperto_sp = {}
  static sg_status_action_shiperto_rt = {}

  static theme: any = {
    core: {
      id: "core",
      sg: {
        shipping_types: this.sg_shipping_types,
        default: {
          status: this.sg_status,
          actions: this.sg_actions,
        },
      },
    },
    entel: {
      id: this.clients[6],
      sg: {
        shipping_types: this.sg_shipping_types_entel,
        default: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_entel_sp,
          status_actions: this.sg_status_action_entel_sp,
        },
      },
    },
    entelperu: {
      id: this.clients[5],
      sg: {
        shipping_types: this.sg_shipping_types_entel,
        default: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_entel_sp,
          status_actions: this.sg_status_action_entelperu_sp,
        },
      },
    },
  }
}
