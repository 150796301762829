import { put_middleware_oim, post_middleware_bff, put_middleware_olm } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAlertSessionError from "./useAlertSessionError"

const useSourcesResources = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { showAlert } = useAlertSessionError()

  const put_upsert_source_action = async (body: any) => {
    try {
      return await put_middleware_oim("inventory/source", body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
    }
  }

  const put_upsert_schedule_logistic_source_action = async (body: any) => {
    try {
      return await put_middleware_olm("sources/schedule-settings", body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
    }
  }

  const post_list_source_combined_action = async ({ body }: any) => {
    try {
      return await post_middleware_bff(`combined/sources/all`, body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      if ([401].includes(error?.response?.status)) return showAlert()
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
    }
  }

  return {
    put_upsert_source_action,
    post_list_source_combined_action,
    put_upsert_schedule_logistic_source_action
  }
}

export default useSourcesResources
