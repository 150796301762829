import { useContext } from "react";
import { GlobalContext } from "context/context";

const useAlertSessionError = () => {
  const { showAlertSessionError } = useContext(GlobalContext);

  const showAlert = () => {
    showAlertSessionError.dispatch(true);
    localStorage.setItem("ALERT_SESSION_ERROR", JSON.stringify(true));
  }

  const hideAlert = () => {
    showAlertSessionError.dispatch(false);
    localStorage.setItem("ALERT_SESSION_ERROR", JSON.stringify(false));
  }

  return {
    showAlert,
    hideAlert
  };
}
 
export default useAlertSessionError;