import { Calendar } from "react-date-range"
import { es } from "date-fns/locale"
import { IPropsDateCalendar } from "interfaces/IDateCalendar"

const DateCalendar = ({ date, setDate }: IPropsDateCalendar) => {
  const handleChangeRange = (date: Date) => {
    setDate(date)
  }

  return (
    <div style={{ margin: '10px 0' }}>
      <Calendar
        onChange={handleChangeRange}
        date={date}
        color="#002eff"
        locale={es}
        editableDateInputs={true}
      />
    </div>
  )
}

export default DateCalendar
