import { createContext } from "react"
import { GlobalStateType } from "context/types/GlobalStateTypes"

/**
 * the global context of the application.
 */
export const GlobalContext = createContext<GlobalStateType>({
  // It is to know if it is in the path of the user information in the header
  userProfileHeader: {
    state: false,
    dispatch: () => null,
  },
  // this is Notifications for the user
  notification: {
    state: [],
    dispatch: () => null,
  },
  //this is to handle global bugs
  errorHander: {
    state: {
      hasError: false,
    },
    dispatch: () => null,
  },
  // SHIPERTO
  displayHeaderRouteName: {
    state: "",
    dispatch: () => null,
  },
  //this is to handle searching...
  userSearch: {
    state: {
      option: "",
      value: "",
    },
    dispatch: () => null,
  },
  state_machine_states: {
    state: [],
    dispatch: () => null,
  },
  showAlertSessionError: {
    state: false,
    dispatch: () => null,
  },
})
