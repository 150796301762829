export const reportsScreenStyle = `
  .reports_screen__wapper_container {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .reports_screen__container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
  }
  .reports_screen__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
  .reports_screen__cont-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .reports_screen__cont-info h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
  }
  .reports_screen__cont-filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1em 0;
  }
  .reports__filters-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
  }
  .reports__filter_search__container button {
    background: #002eff;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    }
  .reports__filter_search__container button:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
  .reports__filter_search__container button:disabled {
    background: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
  .reports_screen__cont-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: auto;
  }
  .reports_screen__cont-table__refresh {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .reports_screen__cont-table__refresh button {
    display: flex;
    padding: 0.5rem; 
    flex-direction: row; 
    gap: 0.25rem; 
    justify-content: center; 
    align-items: center; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    cursor: pointer; 
    color: #4C4C4C;
  }
  .reports_screen__cont-table__refresh button:hover {
    transition: all 0.3s;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); 
    background: #F2F2F2;
  }
  .reports_screen__cont-table table {
    width: 100%;
    min-width: 768px;
    border-collapse: collapse;
    overflow-x: visible;
  }
  .reports_screen__cont-table table thead {
    background: #f5f5f5;
  }
  .reports_screen__cont-table table thead th {
    padding: 1em;
    text-align: left;
    font-weight: 600;
    color: #333;
  }
  .reports_screen__cont-table table tbody tr {
    border-bottom: 1px solid #f5f5f5;
  }
  .reports_screen__cont-table table tbody tr:hover {
    background: #f5f5f5;
  }
  .reports_screen__cont-table table tbody tr td {
    padding: 0.5rem;
    text-align: left;
    color: #333;
  }
  .reports_screen__cont-table table tbody tr td a {
    text-decoration: underline;
    color: blue;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .reports_screen__cont-pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .reports__filter_date__container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: Montserrat;
  }
  .reports__filter_date__container .reports__filter_date__content_date {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .reports__filter_date__content_date > svg.reports__filter_date__calendar {
    color: #002eff;
    font-size: 1.5rem;
  }
  .reports__filter_date__content_date .reports__filter_date__info_date{
    display: flex;
    align-items: center;
    border: 1px solid #002eff;
    border-radius: 18px;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    min-width: 250px;
    height: 36px;
    position: relative;
  }
  .reports__filter_date__info_date svg {
    position: absolute;
    right: 5px;
    font-size: 1.5rem;
    color: #002eff;
    margin-left: 10px;
  }
  .label-success {
    color: #28a745;
  }
  .label-warning {
    color: #ffc107;
  }
  .label-danger {
    color: #dc3545;
  }
  @media (min-width: 768px) {
    .reports__filters-container {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
