export const timeSelectorStyle = `
  .time-selector {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
  }
  .time-display {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 16px;
  }
  .time-unit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .time-unit {
    font-size: 32px;
    font-weight: 600;
    color: white;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #002eff;
    border-radius: 8px;
    margin: 8px 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .time-separator {
    font-size: 32px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    margin: 0 8px;
    padding-bottom: 8px;
  }
  .time-control-btn {
    background: rgba(0, 46, 255, 0.5);
    border: none;
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .time-control-btn:hover {
    background: rgba(0, 46, 255, 0.8);
    transform: scale(1.05);
  }
  .time-control-btn:active {
    transform: scale(0.95);
  }
  .period-toggle {
    background: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 8px;
    margin-left: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
  .period-toggle:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  .period-toggle.am {
    background: #002eff;
  }
  .period-toggle.pm {
    background: #002eff;
  }
`
