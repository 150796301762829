import { Fragment, FunctionComponent, useEffect, useContext, useState } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { useParams } from "react-router-dom"
import { adminSourceScreenStyle } from "overrides/theme/entel/base/pages/AdminSourcesScreen/AdminSourcesScreen.style"
// icons
import { AuthContext } from "context/context"
import { FaSave } from "react-icons/fa"
import LabelSwitch from "overrides/theme/entel/base/components/Commons/LabelSwitch/LabelSwitch"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"
import useSourcesResources from "overrides/theme/entel/base/hooks/v4/useSourcesResources"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import logo_omnix from "assets/images/logo-omnix3.png"

const DetailUserScreen: FunctionComponent<any> = () => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { notification, displayHeaderRouteName } = useContext(GlobalContext)
  const { userId }: any = useParams()
  const [allGroupSources, setAllGroupSources] = useState<any>({
    id: "ALLSOURCES",
    name: "Todas las tiendas/bodegas",
    checked: true,
  })
  const [sourceTyped, setSourceTyped] = useState<string>("")
  const [groupSources, setGroupSources] = useState<any>([])
  const { post_middleware_query_action } = usePostMiddlewareOsrm()
  const [loading, set_loading] = useState(true)
  const user_has_all_sources = Boolean(user?.currentSources.some((src: string) => src === "ALL"))
  const { put_upsert_source_action } = useSourcesResources()

  useEffect(() => {
    const request = async () => {
      let filters: any = {}
      if (Boolean(!user_has_all_sources)) {
        Object.assign(filters, { id: user?.currentSources })
      }
      const res: any = await post_middleware_query_action("search/sources", {
        from: 0,
        size: 1000,
        filters,
        fields: ["id", "name", "enabled"],
      })
      setGroupSources(
        (res.data?.message?.records || []).map((source: any) => ({
          id: source.id,
          name: source.name,
          checked: source.enabled,
        })),
      )
      set_loading(false)
    }
    request()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    displayHeaderRouteName.dispatch(`Administrar tiendas/bodegas`)
    //eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    if (Boolean(groupSources.length) && Boolean(groupSources.some((src: any) => !Boolean(src.checked)))) {
      setAllGroupSources({ ...allGroupSources, checked: false })
    }
    if (Boolean(groupSources.every((src: any) => Boolean(src.checked)))) {
      setAllGroupSources({ ...allGroupSources, checked: true })
    }
    //eslint-disable-next-line
  }, [groupSources])

  const handleCheckGroupSource = (sourceId: string) => {
    setGroupSources(
      groupSources.map((source: any) => {
        if (source.id === sourceId) return { ...source, checked: !source.checked }
        return source
      }),
    )
  }

  const on_submit = async () => {
    try {
      set_loading(true)
      let _data: any
      if (Boolean(allGroupSources?.checked)) {
        _data = groupSources.map((src: any) => ({ id: src?.id, name: src?.name, enabled: true }))
      } else {
        _data = groupSources.map((src: any) => ({ id: src?.id, name: src?.name, enabled: src?.checked }))
      }
      let res: any = await put_upsert_source_action(_data)
      if (res?.data?.code < 400) {
        notification.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            type: "success",
            title: "Tiendas/bodegas actualizadas",
            message: "",
          },
        })
      }
      set_loading(false)
    } catch (error) {
      set_loading(false)
    }
  }

  const filterSources = (query: string) => {
    try {
      const regex = new RegExp(query, "i")
      return groupSources.filter((src: any) => regex.test(src.name) || regex.test(src.id))
    } catch (error) {
      return []
    }
  }

  const sources_to_show: any = sourceTyped ? filterSources(sourceTyped) : groupSources

  return (
    <Fragment>
      {loading && <Loading defaultOpened={true} />}
      <div className="admin_source_screen__wapper_container">
        <div className="admin_source_screen__container">
          <div className="admin_source_screen__body">
            <div className="ass__body_header">
              <h4 className="dus__info_name">Tiendas/Bodegas asignadas</h4>
              <button className="admin_source_screen__body__button_submit" onClick={on_submit}>
                <p>Guardar</p>
                <FaSave size={"1rem"} />
              </button>
            </div>
            <div className="ass__body_search_cont">
              <input
                type="text"
                className="admin_source_screen__input_form"
                placeholder="Id de tienda/bodega"
                disabled={Boolean(userId)}
                onChange={(e: any) => setSourceTyped(e.target.value)}
                value={sourceTyped}
                autoFocus
              />
            </div>
            <div className="ass__body_sources_cont">
              <div className="admin_source_screen__container_sources">
                {Boolean(sources_to_show?.length && !sourceTyped) ? (
                  <div
                    style={{ display: "none" }}
                    className="admin_source_screen__content-checked admin_source_screen__content-checked-title"
                  >
                    <LabelSwitch
                      id="source--ALLSOURCES"
                      value="ALLSOURCES"
                      checked={allGroupSources.checked}
                      onChange={() => {
                        setAllGroupSources({ ...allGroupSources, checked: !allGroupSources.checked })
                        setGroupSources(
                          groupSources.map((srcs: any) => ({ ...srcs, checked: !allGroupSources.checked })),
                        )
                      }}
                      label="Habilitar todas las tiendas/bodegas"
                    />
                  </div>
                ) : null}
                <div className="admin_source_screen__container_list_sources">
                  {Boolean(sources_to_show?.length) ? (
                    sources_to_show.map((source: any, i: number) => (
                      <LabelSwitch
                        key={i}
                        id={`source--${source.id}`}
                        value={source.id}
                        onChange={() => handleCheckGroupSource(source.id)}
                        checked={source.checked}
                        label={`${source.name} (${source.id})`}
                      />
                    ))
                  ) : (
                    <p>
                      {sourceTyped
                        ? `No se encontró la tienda/bodega '${sourceTyped}'`
                        : "No hay tiendas/bodegas asignadas"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="admin_source_screen__footer">
          <p>Powered by</p>
          <img src={logo_omnix} alt="logo" className="logo_omnix" />
        </div>
      </div>
      <style jsx>{adminSourceScreenStyle}</style>
    </Fragment>
  )
}

export default DetailUserScreen
