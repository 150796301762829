export const sortOrderStyled = `
  .sort-order__main_container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .sort-order__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    color: #716F87;
  }
  .sort-order__container p {
    font-size: 1rem;
  }
  .sort-order__container svg {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) {
    .sort-order__main_container {
      flex-direction: row;
      gap: 1rem;
    }
  }
`